import { useState } from 'react';
import { useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';

import {
    useTable,
    useResizeColumns,
    useBlockLayout,
    useSortBy,
    useRowSelect,
    useGlobalFilter,
    useFlexLayout
} from 'react-table';

import "./report-table.styles.scss";


import IconArrowUpward from '../../assets/icons/arrow_upward.svg';
import IconArrowDownward from '../../assets/icons/arrow_downward.svg';

import useFetchReport from '../../effects/use-fetch-report.effect';

import { connect } from 'react-redux';
import { authTokenSelector } from '../../redux/user/user.reselect';
import { selectFilterInput, selectLocalFilterFieldNamesAndValues } from '../../redux/filter/filter.reselect';
import { selectIsRefresh } from '../../redux/refresh/refresh.reselect';
// import { selectDeactivateCheck } from '../../redux/list/list.reselect';
import { selectExpenseType, selectProductOwner, selectScrollPosition, selectTerritoryFilter } from '../../redux/report/report.reselect';
import { setExportData } from '../../redux/export/export.actions';
import { setRefresh } from '../../redux/refresh/refresh.actions';
// import { setDeactivateCheck } from '../../redux/list/list.actions';
import { setScrollPosition } from '../../redux/report/report.actions';
import { setLoadedData } from '../../redux/report/report.actions';
import { createStructuredSelector } from 'reselect';
import Spinner from '../spinner/spinner.component';
import ErrorBox from '../error-box/error-box.component';
import { useEffect } from 'react';
import { selectIsReportDirty, selectLoadedData, selectLoadedSkip, selectLocationFilter, selectPaymentMethod, selectPaymentStatus, selectReportDateRange, selectReportInfo, selectReportSideBarNav, selectReportSideBarNavActiveItem, selectTotalNumItems } from '../../redux/report/report.reselect';
import { format } from 'date-fns';
import { useRef } from 'react';
import Paragraph from '../paragraph/paragraph.componet';
import { setReportSummaryData, setReportDateRange, setPaymentMethod } from '../../redux/report/report.actions';
import { formatNumberToTwoDecimal, stringToNumber } from '../../utils/format-number.util';
import { getThisMonth, getThisWeek, getToday } from '../../utils/date.utils';
import SelectFilter from '../select-filter/select-filter.componenet';
import { withRouter } from 'react-router-dom';
import { displayCellValue } from '../../utils/column-values.util';
import TableCellStatus from '../table-cell-status/table-cell-status.component';

const ReportTable = ({
    reportColumn,
    endpoint,
    authToken,
    filterInput,
    reportDateRange,
    setExportData,
    setRefresh,
    isRefresh,
    setLoadedData,
    loadedData,
    loadedTotalNumItems,
    loadedSkip,
    // deactivateCheck,
    // setDeactivateCheck,
    setScrollPosition,
    scrollPosition,
    history,
    setReportDateRange,
    setReportSummaryData,
    reportSideBarNavActiveItem,
    reportInfo,
    hasNoDate,
    paymentMethod,
    paymentStatus,
    expenseType,
    territory,
    productOwner,
    locationFilter
}) => {
   
    const today = getToday();
    const thisMonth = getThisMonth();
    const [defaultDateRange, setDefaultDateRange] = useState({
        startDate: format(today.startDate, "yyyy-MM-dd"),
        endDate: format(today.endDate, "yyyy-MM-dd"),
    })

    const isMobileView = useMediaQuery({ maxWidth: 991 });

    const limit = 20;
    const [skip, setSkip] = useState(0);

    const dataRef = useRef();
    const skipRef = useRef(0);
    const totalNumItemsRef = useRef();
 
    const [hasMore, setHasMore] = useState(true);

    const { data, dataExport, summaryData, totalData, tableColumns, numItems, error, isLoading } = useFetchReport(
        endpoint,
        authToken,
        setSkip,
        loadedSkip ? loadedSkip : skip,
        limit,
        !reportDateRange || !Object.keys(reportDateRange).length ? defaultDateRange : reportDateRange,
        isRefresh,
        reportSideBarNavActiveItem,
        hasNoDate,
        setLoadedData,
        loadedData,
        loadedTotalNumItems,
        filterInput,
        paymentMethod,
        paymentStatus,
        locationFilter,
        expenseType,
        territory,
        productOwner
    );

    useEffect(() => {
        console.log("Table Columns:", tableColumns);
    }, [tableColumns])

    // const columns = useMemo(() => {
    //     return reportColumn.map((column) => {
    //         for (const key of Object.keys(totalData)) {
    //             if (key === column.accessor) {
    //                 const value = totalData[key];
                    
    //                 // Check if the value is a string with a '%' symbol
    //                 if (typeof value === 'string' && value.includes('%')) {
    //                     column.Footer = value; // Display as-is if it's a percentage string
    //                 } else {
    //                     // Convert to number and format if it's not a percentage string
    //                     const numericValue = stringToNumber(value);
    //                     if (!isNaN(numericValue)) {
    //                         column.Footer = formatNumberToTwoDecimal(numericValue);
    //                     } else {
    //                         column.Footer = value; // Fallback in case it's not a number or percentage
    //                     }
    //                 }
    //             }
    //         }
    
    //         return column;
    //     });
    // }, [totalData, reportSideBarNavActiveItem]);

    // const columns = useMemo(() => {
    //     // Use tableColumns if it is not an empty array, otherwise use reportColumn
    //     const columnsToUse = tableColumns && tableColumns.length > 0 ? tableColumns : reportColumn;
    
    //     return columnsToUse.map((column) => {
    //         for (const key of Object.keys(totalData)) {
    //             if (key === column.accessor) {
    //                 const value = totalData[key];
                    
    //                 // Check if the value is a string with a '%' symbol
    //                 if (typeof value === 'string' && value.includes('%')) {
    //                     column.Footer = value; // Display as-is if it's a percentage string
    //                 } else {
    //                     // Convert to number and format if it's not a percentage string
    //                     const numericValue = stringToNumber(value);
    //                     if (!isNaN(numericValue)) {
    //                         column.Footer = formatNumberToTwoDecimal(numericValue);
    //                     } else {
    //                         column.Footer = value; // Fallback in case it's not a number or percentage
    //                     }
    //                 }
    //             }
    //         }
    
    //         return column;
    //     });
    // }, [tableColumns, totalData, reportSideBarNavActiveItem, reportColumn]);

    const columns = useMemo(() => {
        // Use tableColumns if it is not an empty array, otherwise use reportColumn
        const columnsToUse = tableColumns && tableColumns.length > 0 ? tableColumns : reportColumn;
    
        return columnsToUse.map((column) => {
            // Conditionally add 'Cell' if 'conditionalFormatting' is true
            if (column.conditionalFormatting) {
                column.Cell = ({ value }) => {
                    const isObject = typeof value === 'object' && value !== null;
    
                    if (!isObject) {
                        return displayCellValue(value);
                    } else {
                        return (
                            <div className={'report-table__body__row__cell--right-aligned-mobile-cell'}>
                                <TableCellStatus value={value} />
                            </div>
                        );
                    }
                };
            }
    
            // Add Footer logic if applicable
            for (const key of Object.keys(totalData)) {
                if (key === column.accessor) {
                    const value = totalData[key];
    
                    // Check if the value is a string with a '%' symbol
                    if (typeof value === 'string' && value.includes('%')) {
                        column.Footer = value; // Display as-is if it's a percentage string
                    } else {
                        // Convert to number and format if it's not a percentage string
                        const numericValue = stringToNumber(value);
                        if (!isNaN(numericValue)) {
                            column.Footer = formatNumberToTwoDecimal(numericValue);
                        } else {
                            column.Footer = value; // Fallback in case it's not a number or percentage
                        }
                    }
                }
            }
    
            return column;
        });
    }, [tableColumns, totalData, reportSideBarNavActiveItem, reportColumn]);
    
    const fetchMoreData = async () => {
        if (data.length >= numItems) {
            setHasMore(false)
        }
        setSkip(prevSkip => (prevSkip + limit));
    }

    const defaultColumn = useMemo(
        () => ({
            minWidth: 30,
            width: 140,
            maxWidth: 400
        }),
        []
    );

    const tableMethods = useTable({
        columns,
        data,
        defaultColumn
    },
        useGlobalFilter,
        useResizeColumns,
        useSortBy,
        useRowSelect,
        useFlexLayout
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
        state,
        selectedFlatRows,
        state: {
            globalFilter
        },
        setGlobalFilter,
    } = tableMethods;

    const methods = useForm({
        shouldUnregister: true
    });

    useEffect(() => {
        if (loadedSkip) {
            setSkip(loadedSkip)
        }
    }, [loadedSkip])

    useEffect(() => {
        if (numItems <= limit) {
            setHasMore(false)
        } else if (data.length >= numItems) {
            setHasMore(false)
        } else {
            setHasMore(true)
        }
    })

    useEffect(() => {
        setExportData(dataExport);
    }, [dataExport])

    useEffect(() => {
        if (isRefresh) {
            setHasMore(true);
            setRefresh(false);
        }
    }, [isRefresh])


    useEffect(() => {
        setReportSummaryData([])
    }, [reportSideBarNavActiveItem])

    useEffect(() => {
        if (summaryData.length) {
            setReportSummaryData(summaryData)
        } else {
            setReportSummaryData({})
        }
    }, [summaryData])

    const scrollableDiv = useRef();
    const scrollPositionRef = useRef(0);

    const handleOnScroll = () => {
        scrollPositionRef.current = scrollableDiv.current.scrollTop
    }

    const handleRowClick = (uuid, historyType) => {
        setLoadedData({
            data: dataRef.current,
            skip: skipRef.current,
            totalNumItems: totalNumItemsRef.current,
        })

        let path;
        if (typeof reportInfo.pagePath === 'object' && Object.keys(reportInfo.pagePath).length) {
        
            path = reportInfo.pagePath[historyType];

            if (path.startsWith("/")) {
                path = path.substring(1);
            }

            path = path ? `/${path}/${uuid}` : `/${uuid}`;
            history.push(path);
        } else {
            path = reportInfo && reportInfo.pagePath;
            path = path ? `/${path}/${uuid}` : `/${uuid}`;
            history.push(path)
        }
    }

    useEffect(() => {
        dataRef.current = data
    }, [data])

    useEffect(() => {
        skipRef.current = skip
    }, [skip])

    useEffect(() => {
        totalNumItemsRef.current = numItems
    }, [numItems])

    useEffect(() => {
        if (scrollPosition && scrollPosition !== 0 && skip !== 0) {
            scrollableDiv.current.scrollTop = scrollPosition;
            setScrollPosition(0)
        }
    }, [skip])

    useEffect(() => {
        return () => {
            setScrollPosition(scrollPositionRef.current)
            // setReportDateRange({})
            setReportSummaryData([])
        }
    }, [])

    return (
        <div className="report-table-container">
            {
                isLoading ?
                    <div className='report-table-container__spinner'>
                        <Spinner />
                    </div> :
                    error ?
                        <div className='report-table-container__error'>
                            <ErrorBox
                                error={error}
                                icon={error.status ?
                                    error.status === 404 ? false :
                                        true :
                                    true
                                }
                            />
                        </div> :
                        <>
                            {reportInfo && reportInfo.hasDateRange &&
                                <div className="report-table-container__date-range">
                                    <div className="report-table-container__date-range__single">
                                        {`From: ${format(new Date(!reportDateRange || !Object.keys(reportDateRange).length ? defaultDateRange.startDate : reportDateRange.startDate), "dd/MM/yyyy")}`}
                                    </div>
                                    <div className="report-table-container__date-range__single">
                                        {`To: ${format(new Date(!reportDateRange || !Object.keys(reportDateRange).length ? defaultDateRange.endDate : reportDateRange.endDate), "dd/MM/yyyy")}`}
                                    </div>
                                </div>
                            }

                            <div className="scrollable-report" id="scrollableDiv"
                                ref={scrollableDiv} onScroll={handleOnScroll}
                            >
                                <table className="report-table" {...getTableProps()}>
                                    <thead className="report-table__header">
                                        {headerGroups.map(headerGroup => (
                                            <tr className="report-table__header__group" {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map(column => (
                                                    <th
                                                        className="report-table__header__item"
                                                        {...column.getHeaderProps([

                                                            {
                                                                className: column.className,
                                                                style: column.style
                                                            }
                                                        ])}
                                                    >
                                                        <span {...column.getSortByToggleProps()}>
                                                            {column.render('Header')}
                                                        </span>
                                                        <span className="report-table__header__item__sort">
                                                            {column.isSorted ?
                                                                column.isSortedDesc ?
                                                                    <img
                                                                        src={IconArrowDownward}
                                                                        className="report-table__header__item__sort__icon"
                                                                    /> :
                                                                    <img
                                                                        src={IconArrowUpward}
                                                                        className="report-table__header__item__sort__icon"
                                                                    />
                                                                : ''}
                                                        </span>
                                                        <span style={{ border: '3px solid coral', width: '3px', height: '40px' }}
                                                            {...column.getResizerProps()}
                                                            className={`report-table__header__item__resizer 
                                            ${column.isResizing ?
                                                                    "report-table__header__item__resizer--isResizing" : ""
                                                                }`}
                                                        />
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <InfiniteScroll
                                        className="infinite-scroll"
                                        dataLength={data.length}
                                        next={fetchMoreData}
                                        hasMore={hasMore}
                                        loader={
                                            <div className="infinite-scroll__spinner">
                                                <Spinner position="center" size="large" />
                                            </div>
                                        }
                                        scrollableTarget="scrollableDiv"
                                        endMessage={
                                            <Paragraph>
                                                No more data, you have reached the end.
                                            </Paragraph>
                                        }

                                    >
                                        <tbody className="report-table__body" {...getTableBodyProps()}>
                                            {rows.map(row => {
                                                prepareRow(row)
                                                return (
                                                    <tr className="report-table__body__row" {...row.getRowProps()} >
                                                        {row.cells.map(cell => (
                                                            <td
                                                                className="report-table__body__row__cell"
                                                                {...cell.getCellProps([
                                                                    {
                                                                        className: cell.column.className,
                                                                        style: cell.column.style
                                                                    }
                                                                ])}
                                                                data-label={cell.render('Header')}
                                                                onClick={() => { handleRowClick(row.original.uuid, row.original.historyType) }}
                                                            >
                                                                <span className="report-table__body__row__cell__header">
                                                                    {cell.render('Header')}
                                                                </span>
                                                                <span className="report-table__body__row__cell__data">
                                                                    {cell.render('Cell')}
                                                                </span>
                                                            </td>
                                                        ))}
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </InfiniteScroll>
                                    {reportInfo && reportInfo.tableHasGrandTotal &&
                                        <tfoot className="report-table__footer">
                                            {footerGroups.map(group => (
                                                <td className="report-table__footer__group" {...group.getFooterGroupProps()}>
                                                    {group.headers.map(column => {
                                                        const footerValue = column.render('Footer');
                                                        return (
                                                            <td className={`report-table__footer__item ${typeof footerValue !== 'string' && isMobileView ? "report-table__footer__item--none" : null}`} {...column.getFooterProps()}>
                                                                {typeof footerValue === 'string' ?
                                                                    <div className="report-table__footer__item__single">
                                                                        <span className="report-table__footer__item__single__header">{column.render('Header')}</span>
                                                                        <span className="report-table__footer__item__single__value">{column.render('Footer')}</span>
                                                                    </div> : null
                                                                }
                                                            </td>
                                                        )
                                                    })}
                                                </td>
                                            ))}
                                        </tfoot>
                                    }


                                </table>
                            </div>
                        </>
            }
        </div >

    )
}

const mapStateToProps = createStructuredSelector({
    authToken: authTokenSelector,
    filterInput: selectFilterInput,
    localFilterFieldNamesAndValues: selectLocalFilterFieldNamesAndValues,
    isRefresh: selectIsRefresh,
    reportDateRange: selectReportDateRange,
    scrollPosition: selectScrollPosition,
    reportSideBarNavActiveItem: selectReportSideBarNavActiveItem,
    reportInfo: selectReportInfo,
    paymentMethod: selectPaymentMethod,
    paymentStatus: selectPaymentStatus,
    expenseType: selectExpenseType,
    territory: selectTerritoryFilter,
    productOwner: selectProductOwner,
    locationFilter: selectLocationFilter,
    loadedData: selectLoadedData,
    loadedSkip: selectLoadedSkip,
    loadedTotalNumItems: selectTotalNumItems,
});

const mapDispatchToProps = (dispatch) => ({
    setExportData: (exportData) =>
        dispatch(setExportData(exportData)),
    setRefresh: (data) =>
        dispatch(setRefresh(data)),
    setScrollPosition: (position) =>
        dispatch(setScrollPosition(position)),
    setReportDateRange: (data) =>
        dispatch(setReportDateRange(data)),
    setReportSummaryData: (data) =>
        dispatch(setReportSummaryData(data)),
    setLoadedData: (data) =>
        dispatch(setLoadedData(data))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportTable))
