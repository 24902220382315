import { formatNumber } from "libphonenumber-js";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectFormTableData, selectFormTableLatestData, selectFormTableLatestRows } from "../../redux/form/form.reselect";
import { formatNumberToFiveDecimal, formatNumberToTwoDecimal, stringToNumber } from "../../utils/format-number.util";

import "./form-table-calculate.styles.scss";

const FormTableCalculateDisplay = ({
    fieldName,
    rowNumber,
    calculateType,
    valuesToCalculate,
    formTableLatesRow,
    headerName,
    isMobileView,
    externalFiledValue,
    formTableLatestData
}) => {
    const externalValue = formTableLatestData && formTableLatestData[externalFiledValue];

    const calculate = () => {
        switch (calculateType) {
            case "same":
                const sameValue = valuesToCalculate.map(value => {
                    return (
                        formTableLatesRow.length &&
                            formTableLatesRow[rowNumber] &&
                            formTableLatesRow[rowNumber][value] ?
                            formTableLatesRow[rowNumber][value]
                            : 0
                    )
                })

                return sameValue;
            // case "multiply":
            //     const actualValues = valuesToCalculate.map(value => {
            //         return (
            //             formTableLatesRow.length &&
            //                 formTableLatesRow[rowNumber] &&
            //                 formTableLatesRow[rowNumber][value] ?
            //                 formTableLatesRow[rowNumber][value]
            //                 : 0
            //         )
            //     })

            //     let totalAcutalValues = null
            //     if (actualValues) {
            //         totalAcutalValues = actualValues.reduce((prevValue, value) => {
            //             return prevValue = prevValue * stringToNumber(value)
            //         }, 1)
            //     }

            //     if (externalValue && totalAcutalValues) {                   
            //         return (stringToNumber(totalAcutalValues) * stringToNumber(externalValue))
            //     } else {
            //         return totalAcutalValues
            //     }

            //     return null
            case "multiply":
                const actualValues = valuesToCalculate.map(value => {
                    // Get the field value from the row
                    const fieldValue = formTableLatesRow.length &&
                        formTableLatesRow[rowNumber] &&
                        formTableLatesRow[rowNumber][value];
            
                    // Check if it's the 'length' field and skip multiplication if it's 0 or invalid
                    if (value === "length") {
                        return fieldValue !== undefined && fieldValue !== null && stringToNumber(fieldValue) !== 0 ? 
                            stringToNumber(fieldValue) : 1; // Ignore 'length' if it's 0 or empty
                    } else {
                        // For other fields, return the value or 0 if it's invalid
                        return fieldValue !== undefined && fieldValue !== null ? 
                            stringToNumber(fieldValue) : 0;
                    }
                });
            
                // Perform multiplication on all valid values
                const totalAcutalValues = actualValues.reduce((prevValue, value) => {
                    return prevValue * value;
                }, 1); // Start with 1 for multiplication
            
                // If externalValue exists, multiply by externalValue
                if (externalValue && totalAcutalValues) {
                    return totalAcutalValues * stringToNumber(externalValue);
                } else {
                    return totalAcutalValues;
                }
            
                return null;
            case "add-subtract":
                const addSubtractValues = valuesToCalculate.map(value => {
                    return (
                        formTableLatesRow.length &&
                            formTableLatesRow[rowNumber] &&
                            formTableLatesRow[rowNumber][value] ?
                            formTableLatesRow[rowNumber][value]
                            : 0
                    )
                })

                if (addSubtractValues) {
                    return addSubtractValues.reduce((prevValue, value) => {
                        return prevValue = prevValue + stringToNumber(value)
                    }, 0)
                }

                return null
            case "difference":
                const differenceValues = valuesToCalculate.map(value => {
                    return (
                        formTableLatesRow.length &&
                            formTableLatesRow[rowNumber] &&
                            formTableLatesRow[rowNumber][value] ?
                            formTableLatesRow[rowNumber][value]
                            : 0
                    )
                })

                if (differenceValues && differenceValues.length > 0) {
                    return ((differenceValues.slice(1).reduce((prevValue, value) => {
                        return (prevValue < 0? prevValue * -1 : prevValue) - stringToNumber(value);
                    }, stringToNumber(differenceValues[0]))) * -1);
                }

                return null
        }
    }

    return (
        <div className="form-table-calculate">
            <div className={`${isMobileView ? "form-table-calculate--mobile" : ""}`}>
                {
                    isMobileView &&
                    <label className="form-table-text-field__label">
                        {headerName}
                    </label>
                }
            </div>
            <div className="form-table-calculate__display">
                {
                    formatNumberToFiveDecimal(calculate())
                }
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    formTableLatesRow: selectFormTableLatestRows,
    formTableLatestData: selectFormTableLatestData
})

export default connect(mapStateToProps)(FormTableCalculateDisplay)